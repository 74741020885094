import React, { useEffect, useState } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import LoginButton from './LoginButton'

function Header() {

  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    fetch("/api/user").then(r => r.json()).then(data => {
      if ("error" in data) {
        setUserInfo(null)
      } else {
        setUserInfo(data)
      }
    })
  }, [])

  const logout = () => {
    fetch("/api/logout").then(r => window.location.reload())
  }

  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href={"/"}>DnD Planner</Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            {userInfo !== null ?
              (<Navbar.Text>
                Signed in as: {userInfo?.username} (<a href="#" onClick={logout}>Logout</a>)
              </Navbar.Text>)
              : (<Navbar.Text><LoginButton/></Navbar.Text>)
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header