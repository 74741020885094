import logo from './logo.svg';

import './App.css';
import Header from './components/Header';
import LoginButton from './components/LoginButton';
import ScheduleList from './components/ScheduleList';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ScheduleView from './ScheduleView';
import { createTheme, ThemeProvider } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App" style={{ width: "90%", margin: "auto" }}>
        <Header></Header>
        <div style={{ margin: "50px" }}></div>
        <BrowserRouter>
          <Routes>
            <Route path="schedules" element={<ScheduleList />}>
            </Route>
            <Route path="schedules/:scheduleId" element={<ScheduleView />} />
            <Route path="*" element={<ScheduleList />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
