import React from 'react'
import { useState, useEffect } from 'react'

function LoginButton() {
    const [loginLink, setLoginLink] = useState(null)

    useEffect(() => {
        fetch("/api/login")
        .then(resp => {
            return resp.json()
        })
        .then(data => {
            console.log(data)
            setLoginLink(data.url)
        })
    }, [])

    console.log(loginLink)
    return (
        <a href={loginLink}>Login with Discord</a>
    )
}

export default LoginButton