import React, {useEffect, useState} from 'react'
// import { Table } from 'react-bootstrap'
import {
    Avatar,
    Box,
    CardMedia,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography
} from '@mui/material'
import {useNavigate} from 'react-router-dom'


function ScheduleList() {

    const [schedules, setSchedules] = useState([])
    const navigate = useNavigate()

    function refreshData() {
        fetch("/api/schedules").then(r => r.json()).then(data => {
            if ("error" in data) {
                return
            }
            setSchedules(data)
        })
    }

    useEffect(() => {
        refreshData()
        const intervalId = setInterval(() => {
            refreshData();
        }, 10000)

        return () => {
            clearInterval(intervalId)
        }

    }, [])

    function select_schedule(schedule_id) {
        navigate(`/schedules/${schedule_id}`)
    }

    return (
        <TableContainer component={Paper}>
            <Table striped bordered hover variant='dark'>
                <TableHead>
                    <TableRow>
                        <TableCell>Server</TableCell>
                        <TableCell>Start time</TableCell>
                        <TableCell>Next Campaign</TableCell>
                        <TableCell>Length (Hours)</TableCell>
                        <TableCell>Last Session</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        schedules.map(s => {
                            return (<tr>
                                <TableCell>
                                    <Box sx={{display: 'flex', justifyContent: 'align-vertical'}}
                                         onClick={() => select_schedule(s.id)} style={{cursor: "pointer"}}>
                                        <Typography sx={{alignSelf: 'center'}} pr={"5pt"}>{s.guild_name}</Typography>
                                        <Avatar sx={{alignSelf: 'center'}}
                                                src={`https://cdn.discordapp.com/icons/${s.guild_id}/${s.guild_icon}.png`}/>
                                    </Box>

                                </TableCell>
                                <TableCell>{s.start_time + ":00"}</TableCell>
                                <TableCell>{s.next_campaign?.name}</TableCell>
                                <TableCell>{s.duration / 3600}</TableCell>
                                <TableCell>{String(new Date(s.prev_session_end + "Z").toDateString())}</TableCell>
                            </tr>)

                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ScheduleList