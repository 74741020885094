import {Box, Card, CardContent, CardMedia, Grid, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Calendar from "react-calendar";


function ScheduleView() {
    const {scheduleId} = useParams()
    const [scheduleInfo, setScheduleInfo] = useState(null)
    const [campaigns, setCampaigns] = useState([])
    const [busyDays, setBusyDays] = useState([])
    const [selectedDay, setSelectedDay] = useState(null)
    console.log(selectedDay)
    function refreshData() {
        fetch(`/api/schedules/${scheduleId}`).then(r => r.json()).then(data => {
            if ("error" in data) {
                console.log(data)
                return
            }
            setScheduleInfo(data)
        })
        fetch(`/api/schedules/${scheduleId}/campaigns`).then(r => r.json()).then(data => {
            if ("error" in data) {
                console.log(data)
                return
            }
            setCampaigns(data)
        })
        fetch(`/api/schedules/${scheduleId}/busydays`).then(r => r.json()).then(data => {
            if ("error" in data) {
                console.log(data)
                return
            }
            console.log(data)
            setBusyDays(data)
        })
    }

    useEffect(() => {
        refreshData()
        const intervalId = setInterval(refreshData, 10000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])
    if (scheduleInfo === null) {
        return <></>
    }

    function dayOfWeekAsString(dayIndex) {
        if (dayIndex === null) {
            return "Unknown"
        }
        return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][dayIndex] || '';
    }

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();

    function tileClassName({date, view}) {
        // Add class to tiles in month view only
        if (view === 'month') {
            if (busyDays.find(v => datesAreOnSameDay(new Date(v.date), date))) {
                if (new Date() > date) {
                    return "old-blocked-day"
                }
                return "blocked-day"
            }
            if (campaigns.find(c => c.scheduled_date && datesAreOnSameDay(new Date(c.scheduled_date.split("-")), date))) {
                return "target-day"
            }
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Card sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flex: '2 0 auto'}}>
                                <Typography variant="h5" gutterBottom>
                                    Server: {scheduleInfo?.guild_name}
                                </Typography>
                                <Typography component={"div"} variant="body" sx={{textAlign: "left"}} gutterBottom>
                                    <b>Previous Session:</b> {new Date(scheduleInfo?.prev_session_end).toDateString()}
                                    <br/>
                                    <b>Duration:</b> {scheduleInfo?.duration / 3600} Hour{scheduleInfo?.duration / 3600 <= 1 ? "" : "s"}
                                    <br/>
                                    <b>Start
                                        Time:</b> {scheduleInfo?.start_time % 12}{scheduleInfo?.start_time > 12 ? "PM" : "AM"}
                                    <br/>
                                    <b>Day of Week:</b> {dayOfWeekAsString(scheduleInfo?.day_of_week)}
                                </Typography>
                            </CardContent>
                        </Box>
                        <CardMedia sx={{width: 151}} component={"img"}
                                   image={`https://cdn.discordapp.com/icons/${scheduleInfo.guild_id}/${scheduleInfo.guild_icon}.png`}/>
                    </Card>
                </Grid>
                <Grid item md={1} >
                    {/*<Typography variant={"body"} sx={{color: "white"}}>*/}
                    {/*    Day marked busy by:<br/> {selectedDay && busyDays.find(v => datesAreOnSameDay(new Date(v.date), selectedDay))?.user_name}*/}
                    {/*</Typography>*/}
                </Grid>
                <Grid item md={4}>
                    <Box sx={{textAlign: "left"}}>
                        <Calendar
                            tileClassName={tileClassName}
                            calendarType={"US"}
                            onClickDay={setSelectedDay}
                        />
                        <Typography sx={{marginTop: "5px"}} variant={"body"} sx={{color: "white"}}>
                            <span style={{"width": "4em", "height": "1em", padding: "0pt"}} className={"react-calendar__tile blocked-day"}>Red</span> is a Busy Day
                            <br/>
                            <span style={{"width": "4em", "height": "1em", padding: "0pt"}} className={"react-calendar__tile target-day"}>Green</span> is the next scheduled campaign
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={1} >
                    {(selectedDay && busyDays.find(v => datesAreOnSameDay(new Date(v.date), selectedDay))) ?
                        (<Typography variant={"body"} sx={{color: "white"}}>
                            Day marked busy by:<br/> {selectedDay && busyDays.find(v => datesAreOnSameDay(new Date(v.date), selectedDay))?.user_name}
                        </Typography>) : <></> }
                </Grid>
                <hr/>
                {/*<div style={{ color: "white" }}>{JSON.stringify(scheduleInfo)}</div>*/}
                {/*<div style={{ color: "white" }}>{JSON.stringify(campaigns)}</div>*/}
                {campaigns.map(campaign => {
                    return (

                        <Grid item md={4}>
                            <Card sx={{
                                height: "250pt",
                                backgroundColor: campaign?.scheduled_date == null ? "" : "grey.900"
                            }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        {campaign.name}
                                    </Typography>


                                    <Typography variant={"body2"}>
                                        Run By: {campaign.owner_nickname}
                                    </Typography>
                                    <Typography variant={"body"}>
                                        Scheduled For: {campaign?.scheduled_date || "N/A"}
                                    </Typography>

                                    <Typography variant={"h6"} sx={{color: "primary.dark"}}>
                                        {campaign?.scheduled_date == null ? "" : "Up next!"}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>)
                })}
            </Grid>
        </>
    )
}

export default ScheduleView